<template>
  <div class="dial-container">
    <div
      v-if="!isOpen"
      class="float-button"
      :style="{ width: isMenuOpen ? 'calc(100% - 180px)' : '100%' }"
      @click.stop="toggleDial"
    >
      <div>
        <v-icon class="float-icon" style="color: white"
          >keyboard_double_arrow_down</v-icon
        >

        <span>Rolagem automática</span>
      </div>
    </div>
    <div v-if="false" class="close-btn">
      <v-icon style="color: white">close</v-icon>
    </div>
    <div v-if="isOpen" class="dial-content">
      <v-btn fab small color="secondary" @click="toggleScrolling">
        <v-icon v-if="!isScrolling" :small="$vuetify.breakpoint.xs">
          play_arrow
        </v-icon>
        <v-icon v-if="isScrolling" :small="$vuetify.breakpoint.xs">
          stop
        </v-icon>
      </v-btn>
      <v-btn color="secondary" fab small @click="openSettings">
        <v-icon :small="$vuetify.breakpoint.xs"> settings </v-icon>
      </v-btn>
      <v-btn color="secondary" fab small @click="closeDial">
        <v-icon :small="$vuetify.breakpoint.xs"> close </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatMenu",
  props: {
    openSettingsDialog: {
      type: Function,
      required: true,
    },
    handleStart: {
      type: Function,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    toggleScrolling: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isMenuOpen: {
      get() {
        return this.$store.state.settings.drawer;
      },
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    toggleDial() {
      this.isOpen = !this.isOpen;
      this.toggleScrolling();
    },
    closeDial() {
      this.isOpen = false;
      this.$nextTick(() => {
        if (this.isScrolling) {
          this.toggleScrolling();
        }
      });
    },

    openSettings() {
      if (this.isScrolling) {
        this.toggleScrolling();
      }
      this.openSettingsDialog();
    },
    handleOutsideClick(event) {
      // Verifique se o clique foi fora do componente
      if (!this.$el.contains(event.target)) {
        if (this.isOpen && this.isScrolling) {
          this.toggleScrolling();
        }
      }
    },
  },
};
</script>

<style scoped>
.dial-container {
  position: relative;
}

.dial-content {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.float-button {
  position: fixed;
  bottom: -2px;
  right: 0;
  background-color: #1356a1;
  color: white;
  display: flex;
  justify-content: center;
  z-index: 100;
  padding: 8px;
  border-radius: 12px 12px 0 0;
  cursor: pointer;
  animation: moveUpDown 1.5s ease-in-out infinite;
}

.close-btn {
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 2px;
  cursor: pointer;
  z-index: 1100;
  background-color: gray;
  border-radius: 50%;
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}
</style>
